/*
 * @Author: your name
 * @Date: 2020-07-10 06:30:33
 * @LastEditTime: 2020-07-28 14:28:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /official_website/src/components/layout.js
 */ 
import React, { useEffect, useState } from 'react'
import {Helmet} from "react-helmet";

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = (props) => {

    useEffect(()=>{
        const script = document.createElement("script");
        script.id = "LA_COLLECT";
        script.src = "//sdk.51.la/js-sdk-pro.min.js";
        document.body.appendChild(script);

        const script1 = document.createElement("script");
        script1.text = 'LA.init({id:"JynQ5Gm7dVwGHBnK",ck:"JynQ5Gm7dVwGHBnK",hashMode:true})'
        document.body.appendChild(script1);
    }, [])

    return (
        <div>
            <Helmet>
                <title>bocspace/智建美住</title>
                <meta name="description" content="智建美住科技有限责任公司由松下电器、广联达共同发起成立，以“推动行业发展、成就美好空间”为企业使命，秉承“诚实守信、成就客户、创造美好”的企业价值观，立足工业化建筑的研发、生产与一站式交付服务。" />
                <meta name="kyewords" Content="bocspace,智建美住,箱式房,集装箱房,模块化建筑" />
            </Helmet>
            <div style={{overflowX: 'hidden'}}>
                <Header />
                {props.children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout