/*
 * @Author: your name
 * @Date: 2020-07-10 06:30:33
 * @LastEditTime: 2021-01-18 15:05:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /official_website/src/components/header.js
 */ 
import React, {useState} from 'react'
import { Dropdown, Menu } from 'antd';
import { Link } from 'gatsby'
import { Nav, Navbar } from 'react-bootstrap'

const menu = (
    <Menu>
        <Menu.Item>
            <Link style={{color: '#898989', textAlign: 'center'}} to="/product?type=camp">Camp</Link>
        </Menu.Item>
        <Menu.Item>
            <Link style={{color: '#898989', textAlign: 'center'}} to="/product?type=relief">Residential</Link>
        </Menu.Item>
        <Menu.Item>
            <Link style={{color: '#898989', textAlign: 'center'}} to="/product?type=house">Emergency</Link>
        </Menu.Item>
        <Menu.Item>
            <Link style={{color: '#898989', textAlign: 'center'}} to="/product?type=facility">Commercial</Link>
        </Menu.Item>
    </Menu>
);

const menu2 = (
    <Menu>
        <Menu.Item>
            <Link style={{color: '#898989', textAlign: 'center'}} to="/about?type=companies">Our Companies</Link>
        </Menu.Item>
        <Menu.Item>
            <Link style={{color: '#898989', textAlign: 'center'}} to="/about?type=factory">Our Factory</Link>
        </Menu.Item>
        <Menu.Item>
            <Link style={{color: '#898989', textAlign: 'center'}} to="/about?type=honor">Our Honor</Link>
        </Menu.Item>
    </Menu>
);

const Header = () => {
    
    return(
        // <header>
        //     <h1>
        //         <Link>
        //             Bocspace
        //         </Link>
        //     </h1>
        //     <nav>
                // <ul>
                //     <li><Link to="/">Home</Link></li>
                //     <li><Link to="/product">Product/Service</Link></li>
                //     <li><Link to="/">Proect Cases</Link></li>
                //     <li><Link to="/about">About Us</Link></li>
                //     <li><Link to="/news">News</Link></li>
                //     <li><Link to="/contact">Contact Us</Link></li>
                // </ul>
        //     </nav>
        // </header>
        <Navbar  className="header-nav" fixed="top" >
            <div className="nav-box">
                <Link to="/">            
                    <Navbar.Brand className="bocsapce-brand" >
                    <img
                        alt=""
                        src="/logo@2x(2).png"
                        width="233"
                        height="34"
                        className="d-inline-block align-top"
                    />
                    </Navbar.Brand>
                </Link>
                <Nav className="mr-auto nav-group">
                    {/* <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/product">Product/Service</Nav.Link>
                    <Nav.Link href="/project">Proect Cases</Nav.Link>
                    <Nav.Link href="/about">About Us</Nav.Link>
                    <Nav.Link href="/news">News</Nav.Link>
                    <Nav.Link href="/contact">Contact Us</Nav.Link> */}
                    <ul style={{overflow: 'hidden'}}>
                        <li><Link className='nav-item' to="/">Home</Link></li>
                        <li>
                            <div className='nav-item'>
                                <Dropdown overlay={menu} placement="bottomCenter" arrow>
                                    <div>Product/Service</div>
                                </Dropdown>
                            </div>
                        </li>
                        <li><Link className='nav-item' to="/project">Project Cases</Link></li>
                        <li>
                            <div className='nav-item'>
                                <Dropdown overlay={menu2} placement="bottomCenter" arrow>
                                    <div>About Us</div>
                                </Dropdown>
                            </div>
                        </li>
                        <li><Link className='nav-item' to="/news">News</Link></li>
                        <li><Link className='nav-item' to="/contact">Contact Us</Link></li>
                    </ul>
                </Nav>
            </div>

        </Navbar>
    )
}

export default Header