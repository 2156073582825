/*
 * @Author: your name
 * @Date: 2020-07-10 06:30:33
 * @LastEditTime: 2022-04-27 12:57:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /official_website/src/components/footer.js
 */ 
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'


const Footer = () => {

    return (
            <Container fluid className="px-0">
                <Row noGutters className="footer-col1">
                    <Col className='bocspace-logo'>
                      <img src="logo@2x(1).png" alt='bocspace' />
                    </Col>
                    <Col className="contact-info" xs={6} 
                      style={{display:"flex", flexDirection:"column", alignItems:"flex-start", textAlign: 'center' }}>
                      <span>
                        TEL：+86 189 2120 6895 &nbsp;&nbsp;&nbsp;&nbsp;EMAIL：mua@bocspace.cn    
                      </span>
                      <span>
                        TEL：+86 189 2120 0191 &nbsp;&nbsp;&nbsp;&nbsp;EMAIL：market@bocspace.cn  
                      </span>
                    </Col>
                    <Col className='bocspace-wechat'>
                    <img width={80} height={80} src="wechat.png" alt='bocspace' />
                    </Col>
                </Row>
                <Row noGutters>
                  <Col  className="footer-col2">
                    <footer>
                      <span>
                        © BOCSPACE {new Date().getFullYear()} All Rights Reserved<a href="https://beian.miit.gov.cn" target="_blank" className="site-icp">沪ICP备19033194号-1</a>
                      </span>
                    </footer>
                  </Col>
                </Row>
            </Container>
    )
}

export default Footer
